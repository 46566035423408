import Thoth from '../base/thoth';

class ClientRentabilityService extends Thoth {
    getTax({ month }) {
        return this.fetch({
            url: '/clients-rentability/report/show',
            params: {
                month,
            },
        });
    }

    createTax(data) {
        return this.fetch({
            url: '/clients-rentability/report/store/tax',
            method: 'POST',
            data,
        });
    }

    generateReport({ month }) {
        return this.fetch({
            url: '/clients-rentability/report/store',
            method: 'POST',
            data: { month },
        });
    }
}

export default new ClientRentabilityService();
